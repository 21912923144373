import Vue from 'vue';
import App from './App.vue';

import store from './store';
import router from './router';

Vue.config.productionTip = false;

new Vue({
	data: {
    isPreloadGeneral: false
  },
	store,
	router,
	render: h => h(App),
}).$mount('#app')
