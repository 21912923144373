import Vue  from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		allUsers: [],
		ip: ''
	},

	mutations: {
		SET_ALL_USERS: function (_state, _users)
		{
			_state.allUsers = _users;
		},
		SET_USER_IP: function (_state, _ip)
		{
			_state.ip = _ip;
		}
	},

	actions: {
		getIp: async function ({ commit })
		{
			try
			{
				var oResp = await axios('https://api.ipify.org?format=json');

				commit("SET_USER_IP", oResp.data.ip);

				return oResp.data.ip;
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},
		fetchUsers: async function ({ state, commit })
		{
			if (state.allUsers.length > 0) return state.allUsers;

			const formdata = new FormData();
			formdata.append("action", "profiles");

			const url =
				process.env.NODE_ENV === "development"
					? "/api/files/accounts.json"
					: "/api/profiles.php";

			const options =
				process.env.NODE_ENV === "development"
					? {}
					: {
						method: "POST",
						body: formdata
					};

			const response = await fetch(url, options);
			const users = await response.json();

			const usersArray = Object.values(users);
			commit("SET_ALL_USERS", usersArray);

			return usersArray;
		}
	},

	getters: {
		usersRandom: function (_state)
		{
			return _.shuffle(_state.allUsers)
				.slice(0, 15)
				.map(user => {
					return {
						...user,
						is_new: Math.random() < 0.5,
						online: Math.random() < 0.5
					};
				});
		},
		allUsers: function (state)
		{
			return state.allUsers;
		}
	},

	modules: {}
});
