var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('video',{attrs:{"playsinline":"","autoplay":"","muted":"","loop":"","poster":_vm.sImageURL},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.sVideoURL,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]),_c('sidebar',{class:{'is-active': !_vm.bVisibleSidebar},attrs:{"step":_vm.iCurrStep},on:{"changeStep":_vm.onChangeStep}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__inner"},[(_vm.iCurrStep === 0)?[_vm._m(0),_c('p',{staticClass:"content__subtitle"},[_vm._v("We are ready to help you!")]),_c('button',{staticClass:"custom-btn s1 wide",attrs:{"type":"button"},on:{"click":_vm.onShowSidebar}},[_vm._v("Join Us")])]:(_vm.iCurrStep === 1)?[_vm._m(1),_vm._m(2)]:(_vm.iCurrStep === 2)?[_vm._m(3),_c('p',{staticClass:"content__subtitle"},[_vm._v("Girls from 47 countries")])]:(_vm.iCurrStep === 3)?[_vm._m(4)]:[_vm._m(5),_vm._m(6)]],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title"},[_vm._v("Looking "),_c('br'),_vm._v(" "),_c('b',[_vm._v("for a "),_c('span',[_vm._v("Couple")]),_vm._v("?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__photos"},[_c('img',{attrs:{"src":require("@/assets/t1.jpg"),"width":"114","height":"114","alt":"girl1"}}),_c('img',{attrs:{"src":require("@/assets/t2.jpg"),"width":"114","height":"114","alt":"girl2"}}),_c('img',{attrs:{"src":require("@/assets/t3.jpg"),"width":"114","height":"114","alt":"girl3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title s1"},[_vm._v("More than "),_c('br'),_vm._v(" "),_c('b',[_c('span',[_vm._v("7 000 single girls")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title s1"},[_vm._v("International "),_c('br'),_vm._v(" "),_c('b',[_c('span',[_vm._v("dating platform")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title s2"},[_vm._v("Possibility to realize "),_c('br'),_vm._v(" "),_c('b',[_c('span',[_vm._v("a real meeting")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title s1"},[_vm._v("All our users "),_c('br'),_vm._v(" are "),_c('b',[_c('span',[_vm._v("verified")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content__subtitle"},[_vm._v("We care about our clients and guarantee "),_c('br'),_vm._v(" the security of personal data")])
}]

export { render, staticRenderFns }