<template>
	<div id="app">
		<router-view />

		<Preloader class="preloader-general" :showHide="$root.isPreloadGeneral"/>
	</div>
</template>


<script>
import Preloader from '@/components/Preloader.vue';

export default {
	components: {
		Preloader
	}
}
</script>

<style lang="scss" scoped>

	.preloader-general {
		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		height: 100%;
		width: 100%;
		background-image: -webkit-linear-gradient(left, #0674fd, #00d0f9);
	}
</style>
