<template>
	<aside class="sidebar" :data-step="step">
		<div class="sidebar__inner">
			<form v-if="step === 0" class="auth-form" novalidate @submit.prevent="onSubmit">
				<div class="auth-form__title"><span>JOIN</span> TO OUR CLUB</div>

				<label class="input-wrp">
					<span class="textfield-label">First name:</span>
					<input
						class="textfield"
						:class="{'__invalid': sErrorField === 'name'}"
						type="text"
						name="name"
						placeholder="John"
					/>
					<span v-if="sErrorField === 'name'" class="textfield-error">{{ sErrorMessage }}</span>
				</label>

				<label class="input-wrp">
					<span class="textfield-label">E-mail:</span>
					<input
						class="textfield"
						:class="{'__invalid': sErrorField === 'email'}"
						type="text"
						name="email"
						placeholder="example@email.com"
					/>
					<span v-if="sErrorField === 'email'" class="textfield-error">{{ sErrorMessage }}</span>
				</label>

				<label
					class="checkfield"
					:class="{'__invalid': sErrorField === 'agree'}"
				>
					<input
						type="checkbox"
						name="agree"
					/>
					<i></i>
					<span>I agree with License agreement</span>
					<span v-if="sErrorField === 'agree'" class="textfield-error">{{ sErrorMessage }}</span>
				</label>

				<button type="submit" class="custom-btn s1 wide">Join Us</button>
			</form>

			<template v-else>
				<div class="question-header">
					<template v-if="step === 1">
						<div class="question-title">WELCOME</div>
						<p>A few questions before we start:</p>
					</template>
					<template v-else-if="step === 2">
						<div class="question-title">Mostly girls <br /> of Slavic appearance</div>
					</template>
					<template v-else-if="step === 3">
						<div class="question-title">Your preferences</div>
					</template>
					<template v-else>
						<div class="question-title s1"><span>Congratulations!</span></div>
						<p>We need to verify you. <br />All your data is confidential.</p>
					</template>
				</div>

				<div class="question-body">
					<template v-if="step === 1">
						<p>Have you used online dating <br /> resources before?</p>
					</template>
					<template v-else-if="step === 2">
						<p>Are you ready for the fact that your interlocutors will be from different countries?</p>
					</template>
					<template v-else-if="step === 3">
						<p>Which of these girls do you like best?</p>

						<div class="question-photo">
							<div class="question-photo__item">
								<img src="@/assets/g1.jpg" width="150" height="150" alt="girl1" />
							</div>
							<div class="question-photo__item">
								<img src="@/assets/g2.jpg" width="150" height="150" alt="girl2" />
							</div>
						</div>
					</template>
					<template v-else>
						<div class="question-subtitle">We have a special <br />offer for you!</div>

						<ul>
							<li>Get 30 min chat / videochat</li>
							<li>Unlimited access to view profiles</li>
							<li>Access to premium content</li>
						</ul>

						<button @click="paymentRedirect" class="custom-btn s4" type="button">Welcome package 4.88$</button>
					</template>

					<div v-if="step <= 3" class="question-btns">
						<button class="custom-btn s2" type="button" @click="onChangeStep">{{step !== 3 ? 'Yes' : 'Left'}}</button>
						<button class="custom-btn s3" type="button" @click="onChangeStep">{{step !== 3 ? 'No' : 'Right'}}</button>
					</div>
				</div>

				<div class="question-footer">
					<div v-if="step <= 3" class="question-step">
						<span v-for="i in 3" :key="'step' + i"
							class="question-step__item"
							:class="{'is-active': step === i}"
							v-text="i"
						></span>
					</div>

					<div v-else>
						<p class="copy">Yours respectfully, VictoriyaClub team</p>

						<img src="../assets/footer-logos.png" width="350" height="41" alt="logos" />
					</div>
				</div>
			</template>
		</div>
	</aside>
</template>

<script>
import axios from 'axios';

import { mapState } from "vuex";

export default {
	name: 'sidebar',

	props: {
		step: {
			type: Number,
			default: 0
		}
	},

	data: function ()
	{
		return {
			sErrorField: '',
			sErrorMessage: '',

			redirectURL: '',

			success: false,
			error: ''
		}
	},

	computed: {
		...mapState({
			ip: state => state.ip
		})
	},

	methods: {
		onSubmit: async function (e)
		{
			if (this.__loading) return;


			var nForm = e.target,

				nName = nForm.elements.name,
				sName = nName.value.trim(),

				nEmail = nForm.elements.email,
				sEmail = nEmail.value,

				sAgree = nForm.elements.agree.value,

				rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

			this.sErrorField = '';
			this.sErrorMessage = '';

			if (!sName)
			{
				this.sErrorField = 'name';
				this.sErrorMessage = 'This fileld is required';

				nName.focus();

				return;
			}

			if (sName.length < 3)
			{
				this.sErrorField = 'name';
				this.sErrorMessage = 'Please enter at least 3 characters';

				nName.focus();

				return;
			}

			if (!sEmail)
			{
				this.sErrorField = 'email';
				this.sErrorMessage = 'This fileld is required';

				nEmail.focus();

				return;
			}

			var isEmailValid = rEmail.test(sEmail);

			if (!isEmailValid)
			{
				this.sErrorField = 'email';
				this.sErrorMessage = 'Please, enter a valid email';

				nEmail.focus();

				return;
			}

			sEmail = encodeURIComponent(sEmail.toLowerCase());

			if (!nForm.elements.agree.checked)
			{
				this.sErrorField = 'agree';
				this.sErrorMessage = 'This fileld is required';

				return;
			}

			var sFormData = 'name=' + sName + '&email=' + sEmail +'&sign_agree=' + sAgree;


			this.__loading = true;


			var oSearchParams = new URL(document.location).searchParams;

			sFormData += '&ip=' + this.ip + '&payment=1&pid=' + oSearchParams.get('pid') + '&pid_sid=' + oSearchParams.get('sid');

			try
			{
				var oResp = await axios.post('/api/?action=registration', sFormData);

				this.__loading = false;

				if (oResp.data.error && oResp.data.error !== '')
				{
					var sErrorMessage = 'E-mail is already registered';

					if (oResp.data.error === sErrorMessage)
					{
						this.sErrorField = 'email';
						this.sErrorMessage = sErrorMessage;

						nEmail.focus();

						return;
					}

					alert(oResp.data.error);
				}
				else if (oResp.data.loc)
				{
					this.redirectURL = oResp.data.loc;
					this.onChangeStep();
					this.$root.isPreloadGeneral = false;
				}
			}
			catch (e)
			{
				this.__loading = false;
				this.$root.isPreloadGeneral = false;
				console.error(e);
			}
		},

		async submit() {
			let self = this;
			let params = new URL(document.location).searchParams;
			let data = self.sFormData;
			let ip = self.ip;

			data +=
				"&ip=" +
				ip +
				"&pid=" +
				params.get("pid") +
				"&pid_sid=" +
				params.get("sid");

			await $.post(
					"api/reg.php",
					data,
					function (json)
					{
						if(json.loc)
						{
							self.loc = json.loc;
							self.success = true;
						}
						else
						{
							self.error= json.error;
						}
				}, "json");

			if (self.success)
			{

				this.onChangeStep();
			}
			else if (self.error !== '')
			{
				alert(this.error);
			}
		},

		onChangeStep: function (e)
		{
			if (e && e.target)
			{
				e.target.blur();
			}

			this.$emit('changeStep');
		},

		paymentRedirect: function ()
		{
			let that = this;
			this.$root.isPreloadGeneral = true;

			setTimeout(function()
			{
				document.location = that.redirectURL;
 			}, 1000);
		}
	}
}
</script>

<style lang="scss">
.sidebar
{
	position: relative;
	float: right;
	width: 100%;
	height: 100%;
	margin-left: auto;
	background-color: rgba(#fff, 0.4);
    backdrop-filter: blur(10px);
	z-index: 1;

	&__inner
	{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding: 0 15px;
		overflow-y: auto;
		text-align: center;
	}

	@media (max-width: 991px)
	{
		display: none;

		&.is-active
		{
			display: block;
		}
	}

	@media (min-width: 992px)
	{
		width: 560px;
		width: 45%;
		max-width: 29.166%;
		min-width: 400px;
	}
}

.auth-form
{
	width: 100%;
	max-width: 280px;
	margin: auto;
	text-align: left;

	&__title
	{
		margin-bottom: 30px;
		line-height: 1.2;
		font-size: 3rem;
		font-weight: 700;
		text-align: center;

		span
		{
			color: #00AE99;
		}
	}

	.textfield
	{
		padding-left: 47px;
		background-repeat: no-repeat;
		background-position: 14px center;

		&[name='name']
		{
			background-image: url('../assets/icon-user.svg');
		}

		&[name='email']
		{
			background-image: url('../assets/icon-email.svg');
		}
	}
}

.question
{
	&-header,
	&-body
	{
		max-width: 400px;
	}

	&-header
	{
		padding-top: 8vh;
		padding-bottom: 20px;

		line-height: 1;

		@media (min-width: 576px)
		{
			padding-top: 10vh;
		}
	}

	&-body
	{
		padding-top: 20px;
		padding-bottom: 20px;

		p
		{
			margin-bottom: 30px;
			line-height: 1.3;
			font-size: 2rem;
		}

		ul
		{
			margin-top: 35px;
			line-height: 1.3;
			font-size: 1.8rem;
			text-align: left;

			li
			{
				margin-top: 10px;
				padding-left: 15px;

				&::before
				{
					content: '';
					float: left;
					margin-top: 9px;
					margin-left: -15px;
					width: 6px;
					height: 6px;
					background-color: #00AE99;
					border-radius: 50%;
				}
			}
		}

		.custom-btn.s4
		{
			margin-top: 30px;
			width: 100%;
			max-width: 237px;
		}
	}

	&-footer
	{
		padding-top: 20px;
		padding-bottom: 8vh;

		img
		{
			display: block;
			max-width: 100%;
			height: auto;
		}

		.copy
		{
			padding-bottom: 20px;
			line-height: 1.4;
		}

		@media (min-width: 576px)
		{
			padding-bottom: 10vh;
		}
	}

	&-title
	{
		margin-bottom: 10px;
		line-height: 1.25;
		font-size: 4rem;
		font-weight: 700;
		font-family: 'Gilroy';
		color: #464646;

		&:last-child
		{
			margin-bottom: 0;
		}

		span
		{
			color: #00AE99;
		}

		&.s1
		{
			font-size: 3rem;
			text-transform: uppercase;
		}
	}

	&-subtitle
	{
		line-height: 1.25;
		font-size: 3.5rem;
		font-weight: 700;
		color: #464646;
	}

	&-photo
	{
		margin: 0 -10px;
		overflow: hidden;

		&__item
		{
			float: left;
			width: 50%;
			padding: 0 10px;

			img
			{
				display: block;
				max-width: 100%;
				height: auto;
				border-radius: 10px;
			}
		}
	}

	&-btns
	{
		margin-left: -20px;

		button
		{
			margin-top: 20px;
			margin-left: 20px;
		}
	}

	&-step
	{
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 240px;

		&::before
		{
			content: '';
			position: absolute;
			top: 50%;
			left: 20px;
			right: 20px;
			margin-top: -1px;
			border: 1px solid #FFF;
		}

		&__item
		{
			display: block;
			position: inherit;
			width: 36px;
			height: 36px;
			line-height: 36px;
			font-size: 1.8rem;
			font-weight: 700;
			color: #A1A1A1;
			background-color: #F9F9F9;
			box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
			pointer-events: none;
			border-radius: 50%;

			&.is-active
			{
				color: #FFF;
				background-color: #00AE99;
			}
		}
	}
}
</style>
